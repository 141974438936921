<template>
    <div>
        <form-create name="form" :option="dataOptions" :rule="dataRule" v-model="fApi" :value.sync="value" />
    </div>
</template>

<script>
const dao = require("../api/core/dao");
const utils = require("../api/core/utils");
import { getComponent } from "@/api/core/component";

export default {
    props: {
        rule: {
            type: Array
        },
        options: {
            type: Object
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        $route: {
            handler: function (val) {
                this.url = val.path;
                this.init();
                this.$forceUpdate();
            }
        }
    },
    data() {
        return {
            url: "",
            fApi: {},
            // 用于接收数据源
            value: {},
            dataRule: [],
            dataOptions: {},
            pageKey: "config/setPage",
            recordKey: "record_loading",
            routeKey: "config/setRouter",
            faviconUrlKey: "favicon_url",
            permissionsKey: "permissions"
        };
    },
    async created() {
        this.fApi.data = this.value;
        this.checkAndClearCache();
        this.getRecordLoadConfig();
        await this.getConfig();
        await this.handleFavicon();
        await this.init();
    },
    methods: {
        getApi() {
            return this.fApi;
        },
        checkAndClearCache() {
            // 每日清理缓存
            const currentTime = Date.now();
            const lastClearTimeKey = "lastClearTime";
            const lastClearTime = localStorage.getItem(lastClearTimeKey);
            const time = currentTime - lastClearTime;
            const days = 24 * 60 * 60 * 1000;
            if (!lastClearTime || time > days) {
                localStorage.removeItem(this.pageKey);
                localStorage.removeItem(this.routeKey);
                localStorage.removeItem(this.recordKey);
                localStorage.removeItem(this.faviconUrlKey);
                localStorage.removeItem(this.permissionsKey);
                localStorage.setItem(lastClearTimeKey, currentTime);
            }
        },
        async getConfig() {
            let routeKey = this.routeKey;
            // let routeKey = "config/setRouter";
            // let route = this.$store.getters.router;
            let route = localStorage.getItem(routeKey);
            if (!utils.isNull(route)) {
                route = JSON.parse(JSON.stringify(route));
                this.$store.dispatch(routeKey, JSON.parse(route));
            } else {
                let res = await dao.getConfig("config", "route");
                if (res.success) {
                    const data = res.data;
                    this.$store.dispatch(routeKey, data);
                    localStorage.setItem(routeKey, JSON.stringify(data));
                }
            }
            let pageKey = this.pageKey;
            // let pageKey = "config/setPage";
            // let page = this.$store.getters.page;
            let page = localStorage.getItem(pageKey);
            if (!utils.isNull(page)) {
                page = JSON.parse(JSON.stringify(page));
                this.$store.dispatch(pageKey, JSON.parse(page));
            } else {
                let res = await dao.getConfig("config", "page");
                if (res.success) {
                    const data = res.data;
                    this.$store.dispatch(pageKey, data);
                    localStorage.setItem(pageKey, JSON.stringify(data));
                }
            }
        },
        async getRecordLoadConfig() {
            let recordKey = this.recordKey;
            // let recordKey = "record_loading";
            let record_loading = localStorage.getItem(recordKey);
            if (utils.isNull(record_loading)) {
                let res = await dao.getConfig("config", recordKey);
                if (res.success) {
                    localStorage.setItem(recordKey, res.data);
                }
            }
        },
        // 设置网站图标
        async handleFavicon() {
            let faviconUrlKey = this.faviconUrlKey;
            let favicon_url = localStorage.getItem(faviconUrlKey);
            if (!favicon_url) {
                // const favicon_date = localStorage.getItem("favicon_date");
                // const strDate = utils.dateToString(new Date());
                // if (favicon_date && favicon_date !== null && favicon_date === strDate) {
                //     return;
                // }
                // localStorage.setItem("favicon_date", strDate);
                const origin = window.location.origin;
                let res = await dao.apiGet("uws", "getFavicon", `website=${origin}`);
                if (!res.success) {
                    return;
                }
                favicon_url = res.data;
                localStorage.setItem(faviconUrlKey, favicon_url);
                if (!favicon_url || favicon_url == null) {
                    return;
                }
            }
            var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.rel = "shortcut icon";
            link.href = favicon_url;
            document.getElementsByTagName("head")[0].appendChild(link);
        },
        async init() {
            var params = this.$route.params;
            var resRule = [];
            var resOption = {};
            const app = params.app;
            const code = params.code;
            if (!app || app == null || !code || code == null) {
                this.dataRule = [];
                this.dataOptions = {};
                alert("未找到组件信息");
                return;
            }
            const search = {
                app: app,
                code: code
            };
            const res = await getComponent(search);
            if (!res.success || res.data.length < 1) {
                this.dataRule = [];
                this.dataOptions = {};
                alert("未找到组件信息");
                return;
            }
            let resData = res.data[0];
            resRule = JSON.parse(resData.rule);
            resOption = JSON.parse(resData.options);
            let pageTitle = resOption.form.pageTitle;
            if (pageTitle) {
                document.title = pageTitle;
            }

            // 设置打印配置
            if (resOption.form.printCss) {
                var style = document.createElement("style");
                style.innerHTML = "@media print { @page {" + resOption.form.printCss + "}}";
                window.document.head.appendChild(style);
            }

            //执行加载前脚本
            const _dataRule = resRule;
            const _dataOptions = resOption;
            this.$store.dispatch("api/setAPi", this.fApi);

            // const permissionsKey = "permissions";
            // let permissions = this.$store.getters.permissions;
            const permissionsKey = this.permissionsKey;
            let permissions = localStorage.getItem(permissionsKey);
            let isUpdate = false;
            if (!permissions || permissions == null) {
                isUpdate = true;
                permissions = [];
            } else {
                permissions = JSON.parse(permissions);
                if (permissions.length < 1) {
                    isUpdate = true;
                    permissions = [];
                }
            }
            const headerKey = "HEADER";
            let header = localStorage.getItem(headerKey);
            // 读取按钮权限
            if (header && header !== null && params.code !== "login") {
                if (isUpdate) {
                    let code = "menu";
                    if (utils.getUser().is_admin == 1) {
                        code = "menu1";
                    }
                    try {
                        let menuRes = await dao.view("uws", code, { type: "function" });
                        menuRes.data.map((v) => {
                            permissions.push(v.code);
                        });
                        if (permissions.length > 0) {
                            localStorage.setItem(permissionsKey, JSON.stringify(permissions));
                        }
                    } catch (error) {}
                }
            } else {
                localStorage.setItem(permissionsKey, "");
            }

            if (permissions.length > 0) {
                this.$store.dispatch("permissions/setPermissions", permissions);
            }

            // 获取async函数的构造器
            // 通过上面的构造器创建async方法
            const beforeCreateScript = window.AsyncFunction("vm", "api", "value", "dao", "utils", _dataOptions.form.beforeCreateScript);
            let q = utils.getQueryParams();
            if (q.token) {
                utils.setHeader({ token: q.token });
            }
            if (_dataOptions.form.zoom) {
                document.body.style.zoom = _dataOptions.form.zoom;
            }

            await beforeCreateScript(this, this.fApi, this.value, dao, utils);
            this.fApi.data = this.value;
            this.dataRule = _dataRule;
            this.dataOptions = _dataOptions;
            this.$nextTick(() => {
                this.fApi
                    .all()
                    .filter((item) => item.type === "yes-dataSource")
                    .forEach((item) => {
                        item.__fc__.el && item.__fc__.el.initValue();
                        item.__fc__.el && item.__fc__.el.init();
                    });
                this.value = this.fApi.data;
            });

            this.$nextTick(async () => {
                const afterCreateScript = window.AsyncFunction("vm", "api", "value", "dao", "utils", _dataOptions.form.afterCreateScript);
                await afterCreateScript(this, this.fApi, this.value, dao, utils);

                let formList = document.getElementsByClassName("form-create");
                for (let i = 0; i < formList.length; i++) {
                    formList[i].style = _dataOptions.form.definedCss;
                }

                if (_dataOptions.form.iframeReturnScript) {
                    const iframeReturnScript = window.AsyncFunction("data", "vm", "api", "dao", "utils", _dataOptions.form.iframeReturnScript);
                    window.addEventListener("message", (event) => {
                        iframeReturnScript(event.data, this, this.fApi, dao, utils);
                    });
                }
            });
        }
    }
};
</script>
